exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cautare-[text]-js": () => import("./../../../src/pages/cautare/[text].js" /* webpackChunkName: "component---src-pages-cautare-[text]-js" */),
  "component---src-pages-cautare-index-js": () => import("./../../../src/pages/cautare/index.js" /* webpackChunkName: "component---src-pages-cautare-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wp-page-link-js": () => import("./../../../src/pages/{wpPage.link}.js" /* webpackChunkName: "component---src-pages-wp-page-link-js" */),
  "component---src-templates-article-list-template-js": () => import("./../../../src/templates/articleListTemplate.js" /* webpackChunkName: "component---src-templates-article-list-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-post-list-template-js": () => import("./../../../src/templates/postListTemplate.js" /* webpackChunkName: "component---src-templates-post-list-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

